var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.slider[0])?_c('div',{staticClass:"py-8"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"heading"},[_vm._v(" WizFit Gallery "),_c('div',{staticClass:"hLine"})])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-carousel',{attrs:{"hide-delimiters":_vm.$vuetify.breakpoint.smAndUp,"show-arrows":_vm.arrowCondition && _vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"next",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"arrowButton px-0",staticStyle:{"background-color":"rgba(255, 255, 255, 0.8)"},attrs:{"dark":"","color":"rgba(255, 255, 255, 0.8)","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndUp ? 40 : 20,"color":"#D30024"}},[_vm._v("mdi-chevron-right")])],1)]}},{key:"prev",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"arrowButton px-0",staticStyle:{"background-color":"rgba(255, 255, 255, 0.8)"},attrs:{"dark":"","color":"rgba(255, 255, 255, 0.8)","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndUp ? 40 : 20,"color":"#D30024"}},[_vm._v("mdi-chevron-left")])],1)]}}],null,false,4059418084)},[_vm._l((_vm.slider),function(item,index){return [((index + 1) % _vm.columns === 1 || _vm.columns === 1)?_c('v-carousel-item',{key:index,staticClass:"fill-height",staticStyle:{"height":"100%"}},[_c('v-row',[_vm._l((_vm.columns),function(n,i){return [(+index + i < _vm.slider.length)?[_c('v-col',{key:i,staticClass:"px-3",attrs:{"cols":"12","sm":"4"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[(_vm.slider[index + i].type === 'image')?_c('div',{staticClass:"galleryImageBox pt-15",on:{"click":function($event){return _vm.fullScreenImage(
                            _vm.slider[index + i].file_url,
                            _vm.slider[index + i].type
                          )}}},[_c('img',{staticClass:"galleryImage",attrs:{"src":_vm.slider[index + i].file_url,"alt":""},on:{"mouseover":function($event){_vm.slider[index + i].upHere = true},"mouseleave":function($event){_vm.slider[index + i].upHere = false}}}),_c('v-btn',{staticClass:"shareButton text-capitalize",attrs:{"dark":"","color":"rgba(255, 255, 255, 0.2)"}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-share-variant-outline")]),_c('span',[_vm._v("share")])],1),(_vm.slider[index + i].upHere)?_c('p',{staticClass:"mediaTitle"},[_vm._v(" "+_vm._s(_vm.slider[index + i].title)+" ")]):_vm._e()],1):_c('div',{staticClass:"galleryVideoBox",on:{"click":function($event){return _vm.fullScreenImage(
                            _vm.slider[index + i].file_url,
                            _vm.slider[index + i].type
                          )}}},[_c('div',{staticClass:"player_sizer"},[_c('video',{staticClass:"galleryVideo",attrs:{"preload":"auto|none|metadata","playsinline":""},on:{"mouseover":function($event){_vm.slider[index + i].upHere = true},"mouseleave":function($event){_vm.slider[index + i].upHere = false}}},[_c('source',{attrs:{"src":_vm.slider[index + i].file_url}})])]),_c('img',{staticClass:"playButtonImage",attrs:{"src":require("@/assets/play.png"),"alt":""},on:{"mouseover":function($event){_vm.slider[index + i].upHere = true},"mouseleave":function($event){_vm.slider[index + i].upHere = false}}})])])],1)]:_vm._e()]})],2)],1):_vm._e()]})],2)],1)],1),(_vm.imageViewer.flag)?_c('full-screen-image-viewer',{attrs:{"imageViewer":_vm.imageViewer}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }